import React from "react";
import MainFrame from "./components/MainFrame";
import "./App.scss";
function App() {
  return (
    <div className="App">
      <MainFrame />
    </div>
  );
}
export default App;
